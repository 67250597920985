<script>
  import { db } from '../firebase.js';
  import { getDoc, doc } from 'firebase/firestore';
  import { ref, onMounted } from 'vue';

  export default {
    name: "blogWebCardDetailsSection2",
    props: {
      id: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: 'default'
      }
    },
    setup(props) {
      const task = ref(null);

      onMounted(async () => {
        try {
          const docRef = doc(db, 'blog', props.id);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            task.value = { id: docSnap.id, ...docSnap.data() };
          } else {
            console.error("No such blog exists!");
          }
        } catch (error) {
          console.error("Error fetching blog:", error);
        }
      });
      
      return { task };
    }

  };
</script>

<template>
  <v-container class="main-container">
    <div v-if="!task">Loading...</div>
    <div v-else>
      <div class="title">{{ task.title }}</div>
      <div class="dt-name">BY <span><u>{{ task.author }}</u></span> | {{ task.date }}</div>
      <div class="content-container">
        <div v-for="(block, index) in task.content" :key="index">
          <template v-if="block.type === 'image'">
            <div class="image"><img :src="block.src" :alt="block.alt" /></div>
          </template>
          <template v-else-if="block.type === 'heading'">
            <div class="heading">{{ block.text }}</div>
          </template>
          <template v-else-if="block.type === 'text'">
            <div class="text-content">{{ block.text }}</div>
          </template>
          <template v-else-if="block.type === 'heading2'">
            <div class="heading2"><strong>{{ block.headingText }}</strong> {{ block.text }}</div>
          </template>
          <template v-else-if="block.type === 'list'">
            <ul class="list">
              <li v-for="(item, i) in block.items" :key="i">{{ item }}</li>
            </ul>
          </template>
          <!-- Add more templates for other types of content blocks -->
        </div>
      </div>
    </div>
  </v-container>
</template>


<style scoped>
.title {
  font-family: 'Arial', sans-serif; /* Change to your desired font family */
  font-size: 3rem;
  font-weight: 700; 
  padding: 1px;
  text-align: left; 
}
.dt-name {
  border-bottom: 1.5px solid #F4F4F4;
  padding-bottom: 2rem;
  margin-top: 1rem;
}
.dt-name span {
  color: rgb(70, 70, 255);
}

.heading {
  font-family: 'Arial', sans-serif; /* Change to your desired font family */
  font-size: 28px; /* Adjust font size as needed */
  margin: 20px 0; /* Add margin top and bottom */
}
.heading2 {
  border: 2px solid red;
}

.text-content {
  font-family: 'Arial', sans-serif; /* Change to your desired font family */
  font-size: 18px; /* Adjust font size as needed */
  margin: 10px 0; /* Add margin top and bottom */
}

ul {
  list-style-type: disc; /* Change bullet style as needed */
  margin: 10px 0; /* Add margin top and bottom */
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
  margin-top: 100px; /* Adjust margin top */
  margin-bottom: 2rem;
}

.content-container {
  width: 1000px; /* Set width of content container */
  width: 100%;
}

.image img {
  width: 100%; /* Ensure images fill container width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  margin-top: 1rem;
}

.ad-space {
  width: 20%; /* Set width of ad space */
  margin: 20px; /* Add margin */
}

/* Media query for responsiveness */
@media only screen and (max-width: 600px) {
  .title {
  font-family: 'Arial', sans-serif; /* Change to your desired font family */
  font-size: 2.2rem;
  font-weight: 700; 
  padding: 1px;
  text-align: left;
  }
  .main-container {
    margin-top: 20px; /* Adjust margin top for smaller screens */
  }

  .content-container {
    width: 90%; /* Adjust width for smaller screens */
    margin: 10px; /* Adjust margin */
  }

  .ad-space {
    display: none; /* Hide ad space on smaller screens */
  }
}
.list {
  background-color: #F9F9FE;
  padding: 25px;
  padding-right: 0;
  word-spacing: 0.2rem;
  line-height: 2rem;
  border-left: 5px solid blue;
  border-radius: 8px;
}
.list li {
  color: rgba(0, 0, 0, 0.676);
}
</style>