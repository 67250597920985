<script>
export default {
  name: "blogWebFooterBar",
  data: () => ({
    icons: [
      { text: "mdi-facebook", link: "#" },
      { text: "mdi-twitter", link: "#" },
      { text: "mdi-linkedin", link: "#" },
      { text: "mdi-instagram", link: "#" },
    ],
    navLinks: [
      { name: "Sitemap", route: "/sitemap.xml" }, // Use direct link to sitemap.xml
      { name: "Privacy & Policy", route: "/termsAndContions" }, // Ensure this route matches
      { name: "Disclaimer", route: "/blogWebDisclaimar" },
    ],
  }),
  methods: {
    navigateTo(route) {
      if (route.endsWith(".xml")) {
        window.location.href = route; // Direct navigation to the sitemap.xml file
      } else {
        this.$router.push(route); // Regular Vue Router navigation
      }
    },
  },
};
</script>

<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">
      <!-- Social Media Icons -->
      <v-card-text class="d-flex flex-wrap justify-center">
        <v-btn v-for="(icon, i) in icons" :key="i" class="mx-1 white--text" :href="icon.link" target="_blank" icon>
          <v-icon size="24px" class="hover-btn">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <!-- Footer Text -->
      <v-card-text class="white--text pt-0 white-text">
        Copyright ©2024 All rights reserved | Developed by Bittechbuzz
      </v-card-text>

      <v-divider></v-divider>

      <!-- Navbar at the bottom of the footer -->
      <v-toolbar flat class="white--text justify-center reduced-toolbar">
        <v-btn v-for="(navLink, index) in navLinks" :key="index" text class="white--text mx-0 my-0 px-2 py-1 reduced-btn" @click="navigateTo(navLink.route)">
          {{ navLink.name }}
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-footer>
</template>


<style scoped>
.v-footer {
  background: linear-gradient(135deg, #080808, #343434, #040404);
}

.v-card {
  width: 100%;
  background: linear-gradient(135deg, #080808, #343434, #040404);
}

.white-text {
  color: white;
}

.hover-btn:hover {
  transition: 0.6s;
  transform: scale(1.8);
}

.mx-1 {
  margin-left: 2px;
  margin-right: 2px;
}

.v-toolbar {
  background-color: transparent;
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.reduced-toolbar {
  height: 40px;
}

.reduced-btn {
  font-size: 11px;
}

.reduced-btn:hover{
  color: rgb(189, 189, 189);
  transition: 0.6s;
}

@media only screen and (max-width: 600px) {
  .flex-wrap {
    padding: 2px;
    margin-top: 0.8rem;
    margin-bottom: 1.4rem;
  }

  .mx-4 {
    font-size: 1rem;
  }

  .hover-btn:hover {
    transition: 0.6s;
    transform: scale(1.4);
  }
}
</style>
