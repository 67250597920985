<script>
  import { db } from '@/firebase';
  import { getDocs, collection, query, limit } from 'firebase/firestore';

  export default {
    name: "blogWebMainSection",
    data() {
      return {
        dialog: false,
        blogs: [], // Store the fetched blogs here
      };
    },
    created() {
      this.fetchBlogs(); // Fetch blogs when the component is created
    },
    methods: {
      async fetchBlogs() {
        try {
          // Fetch only the first 3 blogs from Firestore collection
          const q = query(collection(db, 'blog'), limit(3));
          const snapshot = await getDocs(q);
          this.blogs = snapshot.docs.map(doc => ({
            id: doc.id, ...doc.data()
          }));
        } catch (error) {
          console.error("Error fetching blogs:", error);
        }
      },

      navigateToDetails(blogId, blogType) {
        // Pass the blog ID as a route parameter
        if(blogType == '1'){
          this.$router.push({ name: 'blog-card-details1', params: { id: blogId } });

        }else if (blogType == '2') {
          this.$router.push({ name: 'blog-card-details2', params: { id: blogId } });

        }else {
          this.$router.push({ name: 'blog-card-details', params: { id: blogId } });

        }
      },
      navigateToCards() {
        // Navigate to the blog cards section
        this.$router.push({ name: 'blog-cards' });
      }
    },
  };
</script>

<template>
  <section id="blog">
    <h1 class="title-text">Blog</h1>
    <h1 class="font-weight-light sub-title mb-3 text-center title-down-text">Welcome to blog section</h1>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(blog, i) in blogs"
              :key="i"
            >
              <v-hover>
                <template v-slot:default="{ isHovering, props}">
                  <v-card
                    class="card"
                    v-bind="props"
                    shaped
                    :elevation="isHovering ? 20 : 4"
                    :class="{ up: isHovering }"
                    @click="navigateToDetails(blog.id, blog.type)"
                  >
                    <v-img
                      :src="blog.img"
                      max-width="400px"
                      class="d-block ml-auto mr-auto content-image"
                      :class="{ 'zoom-efect': isHovering }"
                      @click="navigateToDetails(blog.id, blog.type)"
                    ></v-img>
                    <h1 class="font-weight-regular" @click="navigateToDetails(blog.id, blog.type)">{{ blog.title }}</h1>
                    <h4 class="font-weight-regular subtitle-1">{{ blog.text }}</h4>
                    <v-btn class="rm-btn" @click="navigateToDetails(blog.id, blog.type)">read more</v-btn>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn @click="navigateToCards" class="vm-btn">VIEW MORE</v-btn>
      </v-row>
    </v-container>
  </section>
</template>

<style scoped>
#blog {
  margin: 0px;
  padding: 0px;
  height: auto;
  margin-bottom: 2rem;
}

.title-text {
  font-size: 2.7rem;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  margin-top: 1rem;
}

.title-down-text {
  font-weight: 100;
  font-size: 1.7rem;
  color: #7b7b7b;
}

.font-weight-bold {
  max-height: 3.2rem; /* Set max height for two lines */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to two lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.content-image {
  scale: 1.2;
  height: 21.5vh;
  width: 42%;
  border-radius: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.card {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  color: #4a4a4a;
  margin-top: 1.6rem;
  min-height: 420px;
  padding: 10px;
  border-radius: 30px 30px 30px 30px;
  transition: 0.5s ease-out;
  background-color: #F4F7F5;
}

.card h1 {
  color: #5b5b5b;
  font-size: 1.6rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top: 2.8rem;
  margin-bottom: 0.8rem;
  max-height: 6rem; /* Set max height for two lines */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to two lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.card h1:hover {
  color: #040404;
  transition: 0.5s ease-out;
  cursor: pointer;
}

.rm-btn {
  background-color: #F4F7F5;
  color: black;
  margin-top: 1rem;
  transition: background-color 0.5s ease-in-out;
}

.rm-btn:hover {
  background: linear-gradient(135deg, #080808, #343434, #040404);
  color: white;
}

.vm-btn {
  background: linear-gradient(135deg, #080808, #343434, #040404);
  color: white;
  margin-top: 2rem;
}

@media only screen and (max-width: 600px) {
  #blog {
    margin-left: 0px;
  }

  .title-down-text {
    padding-left: 11px;
    padding-right: 11px;
  }

  .card {
    margin-left: 0px;
  }

  .card h1 {
    margin-top: 0.8px;
    padding-left: 6px;
    padding-right: 6px;
  }
}
</style>
