<script>
import { db } from '@/firebase'; // Import your Firebase configuration
import { getDocs, collection } from 'firebase/firestore';

export default {
  name: 'blogWebCardSection',
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 9,
      blogs: [], // Store the fetched blogs here
      windowWidth: window.innerWidth, // Track the window width
    };
  },
  created() {
    const page = this.$route.query.page;
    if (page) {
      this.currentPage = parseInt(page, 10);
    }
    this.fetchBlogs(); // Fetch blogs when the component is created
    window.addEventListener('resize', this.handleResize); // Listen to window resize
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize); // Clean up the event listener
  },
  computed: {
    paginatedBlogs() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.blogs.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.blogs.length / this.itemsPerPage);
    },
    visiblePages() {
      const maxVisiblePages = this.windowWidth > 600 ? 5 : 2;
      const half = Math.floor(maxVisiblePages / 2);
      let start = Math.max(this.currentPage - half, 1);
      let end = Math.min(start + maxVisiblePages - 1, this.totalPages);

      if (end - start < maxVisiblePages - 1) {
        start = Math.max(end - maxVisiblePages + 1, 1);
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  methods: {
    async fetchBlogs() {
      try {
        const snapshot = await getDocs(collection(db, 'blog'));
        this.blogs = snapshot.docs.map((doc) => ({
          id: doc.id,
          img: doc.data().headerImage, // Access headerImage field for the image URL
          ...doc.data(),
        }));
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
      this.$router.push({ query: { page: pageNumber } });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.goToPage(this.currentPage + 1);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.goToPage(this.currentPage - 1);
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    navigateToDetails(event, blogId, blogType) {
      // Prevent default action for the right-click event
      if (event.button === 2) {
        event.preventDefault();

        // Define routeOptions for the blog details page based on blogType
        const routeOptions = blogType == '1'
          ? { name: 'blog-card-details1', params: { id: blogId }, query: { page: this.currentPage } }
          : blogType == '2'
          ? { name: 'blog-card-details2', params: { id: blogId }, query: { page: this.currentPage } }
          : { name: 'blog-card-details', params: { id: blogId }, query: { page: this.currentPage } };

        // Open in a new tab (triggered by right-click)
        window.open(this.$router.resolve(routeOptions).href, '_blank');

        // Attempt to refocus the original tab after a short delay
        setTimeout(() => {
          window.focus();
        }, 100);

        // Do not navigate away from the current tab
        return false; // Ensure the current action does not navigate away
      } else {
        // Define routeOptions for the blog details page based on blogType
        const routeOptions = blogType == '1'
          ? { name: 'blog-card-details1', params: { id: blogId }, query: { page: this.currentPage } }
          : blogType == '2'
          ? { name: 'blog-card-details2', params: { id: blogId }, query: { page: this.currentPage } }
          : { name: 'blog-card-details', params: { id: blogId }, query: { page: this.currentPage } };

        // Open in the same tab (triggered by left-click)
        this.$router.push(routeOptions);
      }
    }
  }
};
</script>

<template>
  <section id="blog-cards">
    <h1 class="title-text">Blogs Cards</h1>
    <h1 class="font-weight-light sub-title mb-3 text-center title-down-text">Here's The Blog Cards</h1>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(blog, index) in paginatedBlogs"
              :key="index"
            >
              <v-hover>
                <template v-slot:default="{ isHovering, props }">
                  <!-- Replaced anchor tag with div -->
                  <div
                    @click="navigateToDetails($event, blog.id, blog.type)"
                    @contextmenu.prevent="navigateToDetails($event, blog.id, blog.type)"
                    :class="{ up: isHovering }"
                    v-bind="props"
                  >
                  <v-card
                    class="card"
                    shaped
                    :elevation="isHovering ? 20 : 4"
                    :class="{ up: isHovering }"
                    @click="navigateToDetails($event, blog.id, blog.type)"
                  >
                    <v-img
                      :src="blog.img"
                      class="d-block ml-auto mr-auto content-image"
                      :class="{ 'zoom-efect': isHovering }"
                    ></v-img>
                    <h1 class="font-weight-bold">{{ blog.title }}</h1>
                    <h4 class="font-weight-regular subtitle-1">{{ blog.text }}</h4>
                    <v-btn class="rm-btn">Read more</v-btn>
                  </v-card>

                  </div>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="totalPages > 1" class="pagination">
        <v-btn
          @click="prevPage"
          :disabled="currentPage === 1"
          :class="{
            'Previous-btn': true,
            'disabled-btn': currentPage === 1,
            'active-btn': currentPage > 1
          }"
        >
          Previous
        </v-btn>
        <v-btn
          v-for="page in visiblePages"
          :key="page"
          @click="goToPage(page)"
          :class="{
            'number-btn': true,
            'active-page': currentPage === page
          }"
        >
          {{ page }}
        </v-btn>
        <v-btn
          @click="nextPage"
          :disabled="currentPage === totalPages"
          :class="{
            'next-btn': true,
            'disabled-btn': currentPage === totalPages
          }"
        >
          Next
        </v-btn>
      </v-row>
    </v-container>
  </section>
</template>

<style scoped>
#blog-cards {
  margin-bottom: 10rem;
}
.title-text {
  font-size: 2.7rem;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  margin-top: 8rem;
}
.font-weight-bold {
  max-height: 3.2rem; /* Set max height for two lines */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to two lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.title-down-text {
  font-weight: 100;
  font-size: 1.7rem;
  color: #7b7b7b;
}
.content-image {
  scale: 1.2;
  height: 21.5vh;
  width: 42%;
  margin-top: 3rem;
  margin-bottom: 2rem;
  border-radius: 8px;
}
.card {
  margin-left: 1rem;
  color: #4a4a4a;
  margin-top: 1.6rem;
  min-height: 430px; /* Set a minimum height for all cards */
  padding: 10px;
  border-radius: 30px 30px 30px 30px;
  transition: 0.5s ease-out;
  background-color: #F4F7F5;
}
.card h1 {
  color: #5b5b5b;
  font-size: 1.6rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top: 2.8rem;
  margin-bottom: 0.8rem;
  max-height: 6rem; /* Set max height for two lines */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to two lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.card h1:hover {
  color: #040404;
  transition: 0.5s ease-out;
  cursor: pointer;
}
.rm-btn {
  background-color: #F4F7F5;
  color: black;
  margin-top: 1.3rem;
  transition: background-color 0.5s ease-in-out;
}
.rm-btn:hover {
  background: linear-gradient(135deg, #080808, #343434, #040404);
  color: white;
}
.pagination {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.Previous-btn,
.next-btn {
  margin: 0 5px;
  padding: 10px 15px;
  font-size: 16px;
  background: linear-gradient(135deg, #080808, #343434, #040404);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.Previous-btn.active-btn {
  background: #343434;
}
.number-btn {
  margin: 0 5px;
  padding: 0; /* Removed padding */
  width: 30px; /* Set a fixed width */
  height: 30px; /* Set a fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
.number-btn.active-page {
  background: linear-gradient(135deg, #2a2a2a, #454545, #2a2a2a);
  color: white;
}
/* Media query for responsiveness */
@media only screen and (max-width: 600px) {
  #blog-cards {
    margin-left: 0px;
  }

  .card {
    margin-left: 0px;
  }
  .card h1 {
      margin-top: 0.8px;
      padding-left: 6px;
      padding-right: 6px;
    }

  .pagination {
    margin-top: 5rem;
    flex-wrap: nowrap;
    overflow-x: auto; /* Allow horizontal scrolling */
    white-space: nowrap;
  }
  .Previous-btn,
  .next-btn {
    font-size: 12px;
    padding: 5px 10px;
    margin: 0 3px;
  }
  .number-btn {
    width: 10px; /* Smaller width for mobile */
    height: 34px; /* Smaller height for mobile */
    font-size: 12px;
  }
}
</style>
