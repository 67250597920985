<script>
import { db } from '../firebase.js';
import { getDoc, doc } from 'firebase/firestore';
import { ref, onMounted } from 'vue';
import { getStorageRef, storage, getDownloadURL } from 'firebase/storage';

export default {
  name: "blogWebCardDetailsSection1",
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'default'
    },
    imageUrl: {
      type: String,
      default: 'default'
    }
  },
  setup(props) {
    const task = ref(null);
    const localImageUrl = ref(props.imageUrl);

    onMounted(async () => {
      try {
        // Check if AdSense script already exists to prevent duplicate injection
        if (!document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
          const adScript = document.createElement('script');
          adScript.async = true;
          adScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6106052452077390';
          adScript.crossOrigin = 'anonymous';
          document.head.appendChild(adScript);

          // Initialize Auto Ads
          adScript.onload = () => {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          };
        }

        // Fetch blog data from Firestore
        const docRef = doc(db, 'blog', props.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          task.value = {
            id: docSnap.id,
            ...docSnap.data(),
            content: docSnap.data().content.map(block => {
              if (block.type === 'code') {
                return { ...block, buttonText: 'Copy' };
              }
              return block;
            })
          };
        } else {
          console.error("No such blog exists!");
        }

        // Fetch image URL from Firebase Storage
        const imagePath = 'biology.jpg'; // Replace with your image name if needed
        const imageRef = getStorageRef(storage, imagePath);
        getDownloadURL(imageRef)
          .then(url => {
            localImageUrl.value = url;
          })
          .catch(error => {
            console.error('Error fetching image URL:', error);
          });

      } catch (error) {
        console.error("Error fetching blog or setting up Auto Ads:", error);
      }
    });

    const copyCode = (block) => {
      const textarea = document.createElement('textarea');
      textarea.value = block.code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      block.buttonText = 'Copied!';
      setTimeout(() => {
        block.buttonText = 'Copy';
      }, 2000);
    };

    return { task, localImageUrl, copyCode };
  }
};
</script>

<template>
  <v-container class="main-container">
    <div v-if="!task">Loading...</div>
    <div v-else>
      <div class="title">{{ task.title }}</div>
      <div class="dt-name">BY <span><u>{{ task.author }}</u></span> | {{ task.date }}</div>
      <div class="content-container">
        <div v-for="(block, index) in task.content" :key="index">
          <template v-if="block.type === 'image'">
            <div class="image-container">
              <img :src="block.src" :alt="block.alt" />
            </div>
          </template>
          <template v-else-if="block.type === 'heading'">
            <div class="heading">{{ block.text }}</div>
          </template>
          <template v-else-if="block.type === 'text'">
            <div class="text-content">{{ block.text }}</div>
          </template>
          <template v-else-if="block.type === 'code'">
            <div class="text-code-wrapper">
              <div class="code-header">
                <span class="nav-text">Code</span>
                <button @click="copyCode(block)" class="copy-btn">{{ block.buttonText }}</button>
              </div>
              <div class="text-code">{{ block.code }}</div>
            </div>
          </template>
          <template v-else-if="block.type === 'heading2'">
            <div class="heading2"><strong>{{ block.headingText || block.subHeading }}</strong> <i>{{ block.text }}</i></div>
          </template>
          <template v-else-if="block.type === 'subHeading'">
            <div class="subHeading"><strong>{{ block.subHeading }}</strong> </div>
            <div class="sub-text">{{ block.text }}</div>
          </template>
          <template v-else-if="block.type === 'list'">
            <ul class="list">
              <li v-for="(item, i) in block.items" :key="i">{{ item }}</li>
            </ul>
          </template>
        </div>
      </div>
    </div>
  </v-container>
</template>

<style scoped>
/* Apply box-sizing to all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.title {
  font-family: 'Arial', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  margin: 0 auto; /* Center the title container */
}

.dt-name {
  border-bottom: 1.5px solid #F4F4F4;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.dt-name span {
  color: rgb(70, 70, 255);
}

.heading {
  font-family: 'Arial', sans-serif;
  font-size: 2rem; /* Adjust font size for better responsiveness */
  margin: 10px 0;
}

.heading2 {
  padding: 10px 20px;
  background-color: #F5F7FA;
  border-left: 5px solid rgb(23, 182, 235);
}

.sub-text {
  margin-bottom: 0.8rem;
  margin-left: 18px;
}

.text-content {
  font-family: 'Arial', sans-serif;
  font-size: 1rem; /* Adjust font size for better responsiveness */
  margin-bottom: 20px;
}

ul {
  list-style-type: disc;
  margin: 10px 0;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 68%; /* Ensure full width usage */
  max-width: 1200px; /* Set a max width to control the layout */
  margin-top: 100px;
  margin-bottom: 2rem;
  padding: 0 1rem; /* Add padding for better spacing */
  box-sizing: border-box; /* Ensure padding is included in width */
  overflow: hidden; /* Prevent overflow */
  border-bottom: 1.5px solid #F4F4F4;
}

.content-container {
  width: 100%; /* Ensure full width usage */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.image-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
  width: 100%; /* Ensure container takes full width */
  margin-bottom: 1.5rem;
}

.image-container img {
  width: 100%; /* Ensure images fill container width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  margin-top: 1rem;
}

.ad-space {
  display: none; /* Hide ad space on all screens */
}

.text-code-wrapper {
  position: relative;
  background-color: #2F2F2F;
  border-radius: 10px;
  padding: 2px;
  margin-bottom: 1.5rem;
  width: 100%; /* Ensure wrapper takes full width */
  overflow-x: auto; /* Allow horizontal scrolling if necessary */
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.nav-text {
  font-size: 1rem;
  color: #ffffff;
  margin-left: 0.5rem;
  padding-left: 0.3rem;
}

.text-code {
  background-color: #0D0D0D;
  padding: 1rem; /* Adjust padding */
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap; /* Allow code to wrap to the next line */
  overflow-x: auto; /* Allow horizontal scrolling if necessary */
  color: #ffffff;
  border-radius: 0 0 8px 8px;
}

.copy-btn {
  background-color: #2F2F2F;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 0.6rem;
  margin-bottom: 0.1rem;
  cursor: pointer;
}

.copy-btn:hover {
  background-color: #636363;
}

/* Media query for responsiveness */
@media only screen and (max-width: 600px) {
  .title {
    font-size: 2.2rem; /* Adjust title font size for mobile */
    width: 100%; /* Ensure title takes full width */
    margin: 0 auto; /* Center the title */
  }
  .dt-name {
    font-size: 1rem; /* Adjust font size for mobile */
    padding: 0; /* Remove extra padding */
  }
  .main-container {
    margin-top: 100px;
    width: 100%; /* Ensure full width usage */
    padding: 0 1rem; /* Add padding for better spacing */
  }
  .content-container {
    width: 100%; /* Ensure full width usage */
  }
  .text-code {
    white-space: pre-wrap; /* Allow code to wrap to the next line */
    word-wrap: break-word; /* Break long words to fit the container */
  }
}
</style>
