<script>
export default {
  name: "blogWebHomepage",
  data() {
    return {
      smallScreen: false,
    };
  },
  mounted() {
    // Check screen size on mount
    this.checkScreenSize();

    // Listen for window resize events
    window.addEventListener('resize', this.checkScreenSize);

  },
  beforeUnmount() {
    // Remove the resize event listener when the component is destroyed
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      // Update smallScreen value based on window width
      this.smallScreen = window.innerWidth <= 768;
    },
    goToSection(sectionId) {
      const element = document.querySelector(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }
};
</script>

<template>
  <section id="home">
    <v-parallax dark class="bg-hero">
      <div class="content-wrapper">
        <div class="text-container">
          <h1 class="font-weight-bold display-title">BitTechBuzz</h1>
          <h1 class="font-weight-light white-text">
            TechTide Riding <br>the Waves of Innovation<br>
          </h1>
          <v-btn
            :class="{ 'display-btn': !smallScreen, 'display-btn-small': smallScreen }"
            outlined
            large
            dark
            @click="goToSection('#contact')"
          >
            Get Started
            <v-icon class="ml-2">mdi-arrow-down</v-icon>
          </v-btn>
        </div>
        <div class="image-container" v-if="!smallScreen">
          <div class="images-card"></div>
        </div>
      </div>
    </v-parallax>
  </section>
</template>

<style scoped>
#home {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bg-hero {
  background: linear-gradient(135deg, #000000, #1c2d38);
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 5%;
  margin-top: 2rem;
}

.text-container {
  width: 50%;
  margin-top: 3rem;
}

.image-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.images-card {
  background-image: url("~@/assets/img/hpblogweb.jpg");
  background-position: center;
  background-size: cover;
  height: 450px;
  width: 100%;
  border-radius: 8px;
}

.display-title {
  font-size: 5rem;
  background-color: white;
  background-clip: text;
  color: transparent;
}

.white-text {
  background-color: white;
  background-clip: text;
  color: transparent;
  font-size: 3rem;
}

.display-btn {
  margin-top: 20px;
  font-size: 1.5rem;
  background: transparent;
  color: #FFFFFF;
  outline: 1px solid #FFFFFF;
}

.display-btn-small {
  font-size: 1.2rem;
  background: transparent;
  color: #FFFFFF;
  outline: 1px solid #FFFFFF;
  margin-top: 1rem;
}

.ad-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .bg-hero {
    height: 500px;
  }

  .display-title {
    font-size: 2.8rem;
  }

  .white-text {
    font-size: 2.6rem;
  }

  .content-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .text-container {
    width: 100%;
    margin-top: 4rem;
    margin-left: 0.8rem;
  }

  .image-container {
    display: none;
  }
}

  .ad-container {
    margin-top: 4rem;
    margin-bottom: -7rem;
  }
</style>
