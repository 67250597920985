<script>
import blogWebNavBar from './components/NavBar.vue'
import blogWebHomepage from './components/homepage.vue'
import blogWebAboutSection from './components/AboutSection.vue'
import blogWebContactSection from './components/ContactSection.vue'
import blogWebFooterBar from './components/FooterBar.vue'

export default {
  name: 'App',

  components: {
    blogWebNavBar,
    blogWebHomepage,
    blogWebAboutSection,
    blogWebContactSection,
    blogWebFooterBar,
  },

  data: () => ({
    fab: null, // Flag to control button visibility
    color: "", // Button color
    flat: null, // Button style (flat or elevated)
  }),

  created() {
    const top = window.scrollY || 0;
    if (top <= 60) {
      this.color = "#transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color ="#313232";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.scrollY || e.target.scrollTop || 0;
      this.fab = top > 60; // Update visibility based on scroll position
    },
    toTop() {
      const element = document.querySelector("#home"); // Scroll to the element with id "home"
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
}
</script>

<template>
  <v-app>
    <blogWebNavBar :color="color" :flat="flat"/>
    <v-main class="pt-0">
      <router-view v-if="$route.path === '/'">
        <blogWebHomepage />
        <blogWebAboutSection />
      </router-view>
      <router-view></router-view>
      <blogWebContactSection v-if="$route.path === '/'" />
    </v-main>
    <blogWebFooterBar />
    <!-- Move the FAB outside of the v-main -->
    <div v-if="$route.path === '/'" class="fab-container">
      <VBtn class="ma-btn" v-scroll="onScroll" icon="mdi-arrow-up" size="large" :color="color" elevation="8" @click="toTop" v-show="fab" />
    </div>
  </v-app>
</template>

<style scoped>
  .v-main {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .pointer-events-initial {
    pointer-events: initial;
  }

  /* Adjust the position of the FAB */
  .fab-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  @media only screen and (max-width: 600px) {
    .ma-btn {
      width: 35px;
      height: 35px;
      margin-bottom: 2rem;
    }
  }
</style>
