<script>
import { db } from '../firebase.js';
import { getDoc, doc } from 'firebase/firestore';
import { ref, onMounted } from 'vue';
import { getStorageRef, storage, getDownloadURL } from 'firebase/storage';

export default {
  name: "blogWebCardDetailsSection",
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'default'
    },
    imageUrl: {
      type: String,
      default: 'default'
    }
  },
  setup(props) {
    const task = ref(null);
    const localImageUrl = ref(props.imageUrl);

    onMounted(async () => {
      try {
        const docRef = doc(db, 'blog', props.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          task.value = {
            id: docSnap.id,
            ...docSnap.data(),
            content: docSnap.data().content.map(block => {
              if (block.type === 'code') {
                return { ...block, buttonText: 'Copy' };
              }
              return block;
            })
          };
        } else {
          console.error("No such blog exists!");
        }

        const imagePath = 'biology.jpg'; // Replace with your image name if different
        const imageRef = getStorageRef(storage, imagePath);
        getDownloadURL(imageRef)
          .then(url => {
            localImageUrl.value = url;
          })
          .catch(error => {
            console.error('Error fetching image URL:', error);
          });

        // Inject AdSense script
        const script = document.createElement('script');
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        script.async = true;
        script.crossOrigin = "anonymous";
        document.head.appendChild(script);

        script.onload = () => {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        };
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    });

    const copyCode = (block) => {
      const textarea = document.createElement('textarea');
      textarea.value = block.code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      block.buttonText = 'Copied!';
      setTimeout(() => {
        block.buttonText = 'Copy';
      }, 2000);
    };

    return { task, localImageUrl, copyCode };
  }
};
</script>

<template>
  <v-container class="main-container">
    <div v-if="!task">Loading...</div>
    <div v-else>
      <div class="title">{{ task.title }}</div>
      <div class="dt-name">BY <span><u>{{ task.author }}</u></span> | {{ task.date }}</div>
      <div class="content-container">
        <div v-for="(block, index) in task.content" :key="index">
          <template v-if="block.type === 'image'">
            <div class="image-container">
              <img :src="block.src" :alt="block.alt" />
            </div>
          </template>
          
          <template v-else-if="block.type === 'heading'">
            <div class="heading">{{ block.text }}</div>
          </template>
          <template v-else-if="block.type === 'text'">
            <div class="text-content">{{ block.text }}</div>
          </template>
          <template v-else-if="block.type === 'heading2'">
            <div class="heading2"><strong>{{ block.headingText || block.subHeading }}</strong> <i>{{ block.text }}</i></div>
          </template>
          <template v-else-if="block.type === 'subHeading'">
            <div class="subHeading"><strong>{{ block.subHeading }}</strong> </div>
            <div class="sub-text">{{ block.text }}</div>
          </template>
          <template v-else-if="block.type === 'list'">
            <ul class="list">
              <li v-for="(item, i) in block.items" :key="i">{{ item }}</li>
            </ul>
          </template>
        </div>
      </div>

      <!-- AdSense Auto Ads -->
      <div class="ad-space">
        <!-- AdSense auto ads should appear automatically -->
      </div>
    </div>
  </v-container>
</template>

<style scoped>
  .title {
    font-family: 'Arial', sans-serif;
    font-size: 3rem;
    font-weight: 700; 
    padding: 1px;
    text-align: center;
  }
  .dt-name {
    border-bottom: 1.5px solid #F4F4F4;
    padding-bottom: 1rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .dt-name span {
    color: rgb(70, 70, 255);
  }

  .heading {
    font-family: 'Arial', sans-serif;
    font-size: 28px;
    margin: 10px 0;
  }
  .heading2 {
    margin-bottom: 1.5rem;
    padding: 10px 20px;
    background-color: #F5F7FA;
    border-left: 5px solid rgb(23, 182, 235);
  }

  .subHeading {
    margin-top: 1rem;
  }
  .sub-text {
    margin-bottom: 0.8rem;
    margin-left: 18px;
  }
  .text-content {
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
    margin: 10px 0;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 86%; /* Make width more fluid */
    margin-top: 40px;
    margin-bottom: 2rem;
    padding-left: 8rem; /* Add padding */
    padding-right: 8rem; /* Add padding */
    border-bottom: 1.5px solid #F4F4F4;
  }

  .content-container {
    width: 100%; /* Ensure full width usage */
  }

  .image-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically if needed */
    width: 100%; /* Ensure container takes full width */
    margin-bottom: 1.5rem;
  }

  .image-container img {
    width: 70%; /* Ensure images fill container width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px;
    margin-top: 1rem;
  }

  .ad-space {
    width: 100%; /* Ensure ad container takes full width */
    margin: 20px 0;
  }

  /* Media query for responsiveness */
  @media only screen and (max-width: 600px) {
    .title {
      font-size: 2.2rem;
      text-align: center;
    }
    .main-container {
      margin-top: 20px;
      padding-left: 1rem;
      padding-right: 1rem;
      width: 100%; /* Ensure full width usage */
    }

    .content-container {
      width: 100%;
      margin: 10px 0;
    }

    .ad-space {
      display: none;
    }
    .image-container img {
      width: 100%; /* Ensure images fill container width on small screens */
      height: auto;
      margin-top: 1rem;
    }
  }

  .list {
    background-color: #F9F9FE;
    padding: 25px;
    padding-right: 0;
    word-spacing: 0.2rem;
    line-height: 2rem;
    border-left: 5px solid blue;
    border-radius: 8px;
  }
  .list li {
    color: rgba(0, 0, 0, 0.676);
  }
</style>
