<!-- NavBar.vue -->

<script>
  export default {
    name: 'blogWebNavBar',

    props: {
      color: String,
      flat: Boolean,
    },

    data: () => ({
      drawer: null,
      isXs: false,
      items: [
        ["mdi-home-outline", "Home", "#home"],
        ["mdi-information-outline", "About", "#about"],
        ["mdi-blogger", "Blog", "#blog"],
        ["mdi-email-outline", "Contact", "#contact"],
      ],
    }),

    methods: {
      onResize() {
        this.isXs = window.innerWidth < 850;
      },

      goToSection(sectionId) {
        const router = this.$router;

        if (router.currentRoute.path === '/') {
          // User is on the home page, scroll to the section
          const element = document.querySelector(sectionId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          // User is on another page, navigate to the home page and scroll to the section after navigation
          router.push({ name: 'blog-main' }).then(() => {
            setTimeout(() => {
              const section = document.querySelector(sectionId);
              if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
              }
            }, 500); // Adjust delay as needed to ensure smooth scrolling after navigation
          });
        }
      },
    },

    watch: {
      isXs(value) {
        if (!value && this.drawer) {
          this.drawer = false;
        }
      },
    },

    mounted() {
      this.onResize();
      window.addEventListener("resize", this.onResize, { passive: true });
    },
  };
</script>

<template>
  <div>
    <v-navigation-drawer
      class="nd-head"
      v-model="drawer"
      app
      temporary
      dark
    >
      <v-list class="vl-head">
        <v-list-item>
          <v-list-item-content>
            <div class="logo-div-drawer" justify="center">
              <v-img
                class="rounded-image-drawer"             
                src="@/assets/btb logo.jpg"
                alt="AIfist Logo"
                @click="goToSection('#home')" 
              ></v-img>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense class="vlc-head">
        <v-list-item
          class="vlc-item"
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="goToSection(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">
              {{ text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      white
      class="px-10"
      :class="{ expand: flat }"
    >
      <v-toolbar-title class="v-toolbar-title" :class="{ 'mobile-logo': isXs }">
        <div class="logo-div">
          <v-img 
            class="rounded-image"
            src="@/assets/btb logo.jpg"
            alt="AIfist Logo"
            @click="goToSection('#home')" 
          ></v-img>
        </div>
      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
        :color="'white'"
      />
      <div v-else class="btn-box">
        <v-btn text @click="goToSection('#home')">
          <span class="mr-2">Home</span>
        </v-btn>
        <v-btn text @click="goToSection('#about')">
          <span class="mr-2">About</span>
        </v-btn>
        <v-btn text @click="goToSection('#blog')">
          <span class="mr-2">Blog</span>
        </v-btn>
        <v-btn class="contact-btn" @click="goToSection('#contact')">
          <span class="contact-style mr-2">Contact</span>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<style scoped>
  .v-toolbar-title {
    margin-left: 0; /* Adjust or remove negative margin */ 
  }
  
  .v-toolbar {
    background: linear-gradient(135deg, #080808, #343434, #040404);
    transition: 0.7s;
    position: relative;
    opacity: 0.9;
  }

  .rounded-image {
    height: 35px;
    width: 30px;
    align-items: center;
    transform: scale(1.5); /* Adjust scale if necessary */
    opacity: 1;
    cursor: pointer;
    border-radius: 10px;
  }

  .expand {
    height: 80px !important;
    padding-top: 10px;
    position: relative;
    opacity: 1;
  }

  .btn-box {
    margin-right: 2rem;
  }
  
  .mr-2 {
    color: #D3D7D8;
    font-size: 1rem;
    padding: 8px;
  }

  .mr-2:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }
  
  .contact-btn {
    width: 140px;
    font-size: 2.8rem;
    margin-left: 10px;
    border: 2px solid rgba(236, 234, 234, 0.2);
  }
  
  .contact-style {
    width: auto;
    font-size: 1rem;
    padding: 5px;
    padding-left: 10px;
    margin-left: 5px;
  }

  .contact-style:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }

  .nd-head {
    background: linear-gradient(135deg, #313232, #474747, #313232);
  }

  .vlc-head {
    align-items: center;
    position: relative;
  }
  
  .vlc-item:hover {
    transition: 0.2s;
    border: 3px solid rgba(214, 214, 214, 0.05);
  }

  .justify-center {
    color: white;
    font-size: 1.4rem;
    margin-left: 32px;
    position: absolute;
  }

  .subtitile-1 {
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    margin-left: 80px;
    width: 50%;
    padding: 5px;
  }

  .logo-div {
    width: 35px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-left: 1rem;
  }

  @media only screen and (max-width: 600px) {
    .rounded-image {
      height: 40px;
      width: 35px;
      transform: scale(1.5);
      border-radius: 8px;
    }

    .logo-div {
      width: 35px;
      height: 40px;
      display: flex;
      justify-content: left; /* Align to the left */
      align-items: left;
      padding: 5px;
      box-sizing: border-box;
      margin-left: 0px; /* Remove margin */
    }

    .v-app-bar-nav-icon {
      transform: translateX(1.5rem);
    }

    .logo-div-drawer {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-left: 85px; /* Adjust this value to center the logo */
      box-sizing: border-box;
    }

    .rounded-image-drawer {
      height: 30px;
      width: 30px;
      transform: scale(1.5);
      border-radius: 6px;
    }

    .mobile-logo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0; /* Remove padding */
      margin-left: 0; /* Remove margin */
    }
  }
</style>
