import { createRouter, createWebHistory } from 'vue-router';

// Import your components here
import blogWebMainSection from './components/BlogSection.vue';
import blogWebCardSection from './components/BlogCards.vue';
import blogWebCardDetailsSection from './components/BlogCardsDetails.vue';
import blogWebCardDetailsSection1 from './components/BlogCardsDetails1.vue';
import blogWebCardDetailsSection2 from './components/BlogCardsDetails2.vue';
import termsAndConditions from './components/TermsConditions.vue';
import blogWebDisclaimar from './components/Disclaimar.vue';

const routes = [
  {
    path: '/',
    name: 'blog-main',
    component: blogWebMainSection,
  },
  {
    path: '/blog-cards',
    component: blogWebCardSection,
    name: 'blog-cards',
    props: true,
  },
  {
    path: '/blog-card-details/:id',
    component: blogWebCardDetailsSection,
    name: 'blog-card-details',
    props: true,
  },
  {
    path: '/blog-card-details1/:id',
    component: blogWebCardDetailsSection1,
    name: 'blog-card-details1',
    props: true,
  },
  {
    path: '/blog-card-details2/:id',
    component: blogWebCardDetailsSection2,
    name: 'blog-card-details2',
    props: true,
  },
  {
    path: '/termsAndContions',
    component: termsAndConditions,
    name: 'terms',
    props: true,
  },
  {
    path: '/blogWebDisclaimar',
    component: blogWebDisclaimar,
    name: 'Disclaimar',
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // Always scroll to the top when navigating to a new route
    return { top: 0 };
  },
});

export default router;
