// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAo60AQ8e2WjWMzLr_d3E9ZicIhYsGk0dM",
  authDomain: "vue-firebase-curd-51b3c.firebaseapp.com",
  projectId: "vue-firebase-curd-51b3c",
  storageBucket: "vue-firebase-curd-51b3c.appspot.com",
  messagingSenderId: "114958724290",
  appId: "1:114958724290:web:b3a3c2a1be087ce8ccdf5b",
  measurementId: "G-5KM8SB46JC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);


export {db, storage};