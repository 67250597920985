<script>
  import emailjs from 'emailjs-com';

  export default {
    name: "blogWebContactSection",
    data: () => ({
      icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
      valid: true,
      name: "",
      formRef: null,
      nameRules: [
        (v) => !!v || "The name field is required",
        (v) => (v && v.length >= 6) || "The name must be longer than 6 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "The email field is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      textArea: "",
      textAreaRules: [
        (v) => !!v || "The text field is required",
        (v) => (v && v.length >= 20) || "Minimum of 20 characters",
      ],
      lazy: false,
      snackbar: {
        enabled: false,
        text: '',
        color: ''
      }
     }),
    mounted() {
      // Access the form element after the component has mounted
      this.formRef = this.$refs.form;
    },
    methods: {
      submit() {
        const vForm = this.$refs.form;
        if (vForm.validate()) {
          const formData = {
            name: this.name,
            email: this.email,
            message: this.textArea,
          };
          console.log(formData);
          emailjs
          .send('service_zf3qrhk', 'template_ipnv2hd', formData, 'wcL738pDrAL4gjwWA')
          .then(
            () => {
              console.log('SUCCESS!');
              // handle success, show snackbar or other feedback
              this.snackbar = { enabled: true, text: 'Form submitted successfully!', color: 'success' };

              // Clear the input fields
              this.name = "";
              this.email = "";
              this.textArea = "";

              // Reset form validation
              this.$refs.form.resetValidation();

              // Hide the snackbar after 2 seconds
              setTimeout(() => {
                this.snackbar.enabled = false;
              }, 2000);
            },
            (error) => {
              console.log('FAILED...', error.text);
              // handle failure, show snackbar or other feedback
              this.snackbar = { enabled: true, text: 'Form submission failed!', color: 'error' };

              // Hide the snackbar after 2 seconds
              setTimeout(() => {
                this.snackbar.enabled = false;
              }, 2000);
            },
          );
        } else {
          console.error('Form element not found');
          // handle error, show snackbar or other feedback
          this.snackbar = { enabled: true, text: 'Form element not found!', color: 'error' };

          // Hide the snackbar after 2 seconds
          setTimeout(() => {
            this.snackbar.enabled = false;
          }, 2000);
        }
      },
    },
  };
</script>

<template>
  <section class="pb-8" id="contact">
    <v-container fluid class="main-cont contain-css">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="display-1">Contact-Us</h1>
              <h3 class="cont-title">Get in Touch</h3>
              <h3 class="font-weight-light mt-3">
                we strive to provide exceptional service. Whether you seek clarification on our services, require assistance, or simply wish to establish a connection, we are here to assist you every step of the way.
              </h3>
              <h3 class="font-weight-light mt-3">
                Phone: <a class="phone-color">+923013244361</a>
              </h3>
              <span class="font-weight-light">
                Email: <a class="email-color" href="mailto:support@aifist.com">anshalali73@gmail.com</a>
              </span>
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy" id="contact-form">
                <v-text-field
                class="name-ind"
                  v-model="name"
                  :rules="nameRules"
                  label="Name"
                  required
                />
  
                <v-text-field
                class="email-ind"
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  required
                />
  
                <v-textarea
                class="message-ind"
                  v-model="textArea"
                  :rules="textAreaRules"
                  label="Message"
                  required
                />
  
                <v-btn
                  :disabled="!valid"
                  :dark="valid"
                  rounded
                  block
                  class="mt-3 submit-btn"
                  @click="submit"
                  >
                  Submit
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar.enabled"
      timeout="3000"
      right
      top
      :color="snackbar.color"
      >
      {{ snackbar.text }}
  
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.enabled = false"
          >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>
  
<style scoped>
  #contact {
    background-color: #f4f7f5;
  }
  .display-1 {
    font-size: 2.1rem;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .cont-title {
    margin-top: 5px;
    font-size: 1.4rem;
  }

  .name-ind {
    background-color: #f4f7f5;
    transition: border-bottom-color 0.9s ease;
  }
  .email-ind {
    background-color: #f4f7f5;
  }

  .message-ind {
    background-color: #f4f7f5;
  }
  .contain-css{
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .email-color
  {
    color: #2E2E2E;
    font-weight: bold;
  }
  .email-color:hover {
    /* Add your hover styles here */
    color: #4a5a63; /* Change to the color you want on hover */
    transition: 0.6s;
}
.phone-color{
  color: #2E2E2E;
  font-weight: bold;
}
.phone-color:hover {
  /* Add your hover styles here */
  color: #4a5a63; /* Change to the color you want on hover */
  transition: 0.6s;
}
.submit-btn {
  background: linear-gradient(135deg, #080808, #343434, #040404);
  color: white;
}
</style>