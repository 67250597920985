<script>
  export default {
    name: "blogWebAboutSection",
  };
</script>
<template>
  <section id="about">
    <v-container fluid>
      <h1 class="main-title text-center">About Us</h1>
      <h1 class="font-weight-light sub-title mb-3 text-center">Safe Future with Technology</h1>
      <v-row align="center">
        <v-col cols="12">
          <v-row align="center" class="ti-div">
            <v-col cols="12" md="6" class="add">
              <v-row class="text-card">
                <v-col cols="12" class="d-flex align-center">
                  <P>
                    Welcome to BitTechBuzz—your go-to destination for all things cutting-edge in the tech world. We are dedicated to demystifying the most complex and fast-evolving areas of technology, including Artificial Intelligence, Blockchain, Cryptocurrency, Web3, and Coding. Whether you're a tech enthusiast, a developer, or a business owner looking to stay ahead of the curve, BitTechBuzz is here to guide you through the latest innovations shaping our future.
                  </P>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <div>
                    <h2 class="font-weight-bold mb-2">Our Mission</h2>
                    <p>
                      At BitTechBuzz, our mission is to make technology accessible and understandable for everyone. In a world driven by digital transformation, we believe that knowledge is the key to unlocking endless possibilities. That's why we focus on providing in-depth, easy-to-follow tutorials, industry insights, and thought leadership to help you navigate the ever-changing tech landscape.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <div>
                    <h2 class="font-weight-bold mb-2">What We Offer</h2>
                    <p>
                      <B>In-Depth Blog Articles:</B> From AI advancements to Bitcoin trends, our articles explore the latest in tech with clear explanations and real-world examples. <BR></BR>
                      <B>Tech Tutorials:</B> Learn how to code, build blockchain applications, and integrate AI technologies with our step-by-step guides.<BR></BR>
                      <B>Industry Insights:</B> Stay updated with news and analysis on emerging technologies like Web3 and Cryptocurrency, helping you stay ahead of market trends. <BR></BR>
                      <B>Coding Resources:</B> We offer resources for developers of all levels, whether you're just getting started or are an experienced coder looking to expand your skillset. <BR></BR>
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <div>
                    <h2 class="font-weight-bold mb-2">Why Choose BitTechBuzz?</h2>
                    <p>
                      We are passionate about technology and committed to making it accessible to all. Our team of tech enthusiasts and industry experts carefully curates content to ensure you gain the knowledge you need while enjoying the learning process. At BitTechBuzz, we believe that technology should empower, not intimidate.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <div>
                    <h2 class="font-weight-bold mb-2">Our Vision</h2>
                    <p>
                      Our vision is to build a community of learners, innovators, and thinkers who are not only keeping pace with the rapid changes in technology but also shaping the future of it. By providing valuable insights and tutorials, we hope to inspire curiosity and innovation.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <div>
                    <h2 class="font-weight-bold mb-2">Join Us on This Journey</h2>
                    <p>
                      The tech world is full of possibilities, and BitTechBuzz is here to help you unlock them. Join us as we explore the exciting innovations that are changing the way we live, work, and interact with the world.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <P>
                    Stay curious, stay informed, and stay ahead with BitTechBuzz!
                  </P>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" class="d-none d-md-flex">
              <v-img
                src="@/assets/img/aboutimg.jpeg"
                class="aboutImage d-block ml-auto mr-auto"
                max-width="600px"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style scoped>
#about {
  max-height: 150vh;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background-color: #F4F7F5;
  margin-bottom: 3rem; /* Add margin-bottom to separate from the Blog section */
}

.aboutImage {
  width: 85%;
  border-radius: 50px;
}
.d-none {
  margin-left: 3.5rem;
}

.main-title {
  margin-top: 0.7rem;
  font-size: 2.7rem;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
}

.sub-title {
  margin-bottom: 5rem;
  font-size: 1.7rem;
  color: #7b7b7b;
}

.ti-div {
  height: 130vh; /* Adjust the height as needed */
}

.text-card {
  height: 110vh;
  text-align: left;
  line-height: 30px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 1.5rem;
  width: 100%;
  background-color: #F4F7F5;
  border-radius: 10px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.4);
  overflow-y: auto; /* Add scroll if content is too large */
}

.text-card p {
  text-align: left;
}

.mb-2 {
  margin-top: 7px;
}

@media only screen and (max-width: 1024px) {

  .text-card {
    height: 100vh;
    width: 98%;
  }
}

@media only screen and (max-width: 600px) {
  #about {
    height: 180vh;
  }
  .sub-title {
    text-align: center;
  }
  .text-card {
    height: 112vh;
    width: 97%;
    margin-left: 5px;
  }
  .text-card p {
    text-align: left;
  }
}
</style>

